import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Image from '../../image'
import { Box } from '@material-ui/core';


interface IProps {
    faculty: Faculty
}

interface Faculty {
    title: string
    image: string
    name: string
}


export default function FacultyCard({ faculty, ...props }: IProps) {
    return (
        <Card style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight:175
        }}>
            <Box sx={{ maxWidth: 345 }} style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <div className="faculty-image" style={{ padding: 10, minHeight: 20, width: "130px", height: "130px" }}>
                    <Image alt={faculty.title} filename={faculty.image} />
                </div>

                <Typography variant="h6" color="inherit" component="p" style={{ fontSize: 18, padding: 5, paddingTop: 0, paddingBottom: 0 }}>
                    {faculty.name}
                </Typography>

                <Typography variant="subtitle1" color="inherit" component="p" style={{ fontSize: 13, padding: 10, paddingTop: 0 }}>
                    {faculty.title}
                </Typography>
            </Box>
        </Card>
    );
}
