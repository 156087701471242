import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import OurJyothisFamily from "../components/ourjyothisfamily/ourjyothisfamily"

// markup
const JyothisPage = () => {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Layout>
          <SEO title="Our Jyothis Family" />
          <OurJyothisFamily />
        </Layout>
      </ThemeProvider>
    </React.Fragment>
  )
}

export default JyothisPage
