import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Image from "../image"
import Bar from "../bar"
import AppointmentCard from "../home/components/appointment-card"
import { Box, Paper } from "@material-ui/core"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import FacultyCard from "./components/FacultyCard"

const OurJyothisFamily = () => {

    const patron = {
        id: 1,
        image: "bishpos (2).jpg",
        title: "Our Patron",
        description:
            "Most. Rev. Dr. Theodosius Marthoma Metropolitan",
    }

    const president = {
        id: 2,
        image: "Founder.jpg",
        title: "Our Founder & President",
        description:
            "Rt. Rev. Thomas Mar Timotheos Episcopa"
    }

    let family = [
        {
            id: 1,
            image: "bishpos (2).jpg",
            title: "Our Patron",
            description:
                "Most. Rev. Dr. Theodosius Marthoma Metropolitan",
        },
        {
            id: 3,
            image: "Founder.jpg",
            title: "Our Founder & President",
            description:
                "Rt. Rev. Dr. Euyakim Mar Coorilos Suffragan Metropolitan",
        },
        {
            id: 5,
            image: "VinodEasow.jpg",
            title: "Director",
            description:
                "Rev. Vinod Easow",
        }
    ]


    const facultyData = [
        {
            id: 11,
            title: 'Doctor on call',
            image: 'Dr.Biju Ninan photo.jpg',
            name: 'Dr.Biju Ninan (MBBS, DLO)'
        },
        {
            id: 12,
            title: 'Doctor on call',
            image: 'Dr. Mary Ninan photo.jpg',
            name: 'Dr. Mary Ninan (MBBS, DOMS)'
        },
        {
            id: 13,
            title: 'Doctor on call',
            image: 'Dr. Vinod Koshy photo.jpg',
            name: 'Dr. Vinod Koshy (MBBS,MD)'
        },
        {
            id: 1,
            title: 'Headmistress and Vocational Training In-charge',
            image: 'Jomol M.I photo.jpg',
            name: 'Jomol M.I'
        },
        {
            id: 8,
            title: 'Speech therapist',
            image: 'Krupa Susan Chacko photo.jpg',
            name: 'Krupa Susan Chacko (MASLP)'
        },
        {
            id: 9,
            title: 'Speech therapist',
            image: 'Jency P Scaria photo.jpg',
            name: 'Jency P Scaria (BASLP)'
        },
        {
            id: 2,
            title: 'Special teacher',
            image: 'Vipi Antony photo.jpg',
            name: 'Vipi Antony'
        },
        {
            id: 3,
            title: 'Special teacher',
            image: 'Vijilipriya photo.jpg',
            name: 'Vijilipriya'
        },
        {
            id: 4,
            title: 'Special teacher',
            image: 'Anjana Anand photo.jpg',
            name: 'Anjana Anand'
        },
        {
            id: 5,
            title: 'Special teacher',
            image: 'Jyothirmayi S photo.jpg',
            name: 'Jyothirmayi S'
        },
        {
            id: 6,
            title: 'Special teacher',
            image: 'Abhirami T B photo.jpg',
            name: 'Abhirami T B'
        }
    ]

    return (
        <div style={{ paddingTop: 30, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <Grid container spacing={3} style={{ paddingBottom: 20 }} alignItems="center" direction="column">
                    <Typography style={{ display: "block" }} variant="h5">Our Jyothis Family</Typography>
                    <Bar />
                </Grid>
            </Box>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}
            >
                {family.map(x => (
                    <Box key={x.id} >
                        <AppointmentCard management={x} />
                    </Box>
                ))}

            </div>

            <div style={{ display: 'flex', justifyContent: 'center', width: '90%' }}>
                <Grid container spacing={3} style={{ textAlign: "center" }} direction="row" justifyContent="center" alignItems="center" alignContent="center">
                    {facultyData.map(faculty => (
                        <Grid item xs={12} sm={4} key={faculty.id} >
                            <FacultyCard faculty={faculty} />
                        </Grid>
                    ))}
                </Grid>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Paper variant="elevation" elevation={12} square style={{ width: '90%', margin: "100px 10px", padding: 50, fontStyle: "italic" }}>

                    <Typography variant="h5" color="inherit" >
                        Letter from the President:
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        style={{ marginTop: 20, marginBottom: 10, color: "#154b68" }}
                        className="body-font"
                    >
                        Every child has the right to have the same opportunities as others regardless of the difficulties and problems they face. Special Education is of great importance for children with intellectual challenges because it gives them the opportunity to have quality education in line with their unique needs. It helps the child to gain a high level of independence and potential.
                        According to UNICEF about 93 million children around the world have been diagnosed with some form of disability. Unfortunately, over 50% of them do not have the facility to attend any form of the training.
                        <br />
                        <br />
                        Bearing this in mind, in 1994 we have started Jyothis Special School with one teacher and three children in a rented house at Punnamood, Mavelikara. On our path of development, God has blessed us abundantly. Now the school has got its own building with ten teaching staff and six non teaching staff. We have well equipped Speech Therapy and Physiotherapy units with qualified and experienced therapists.
                        Jyothis is one among the various projects of Chengannur-Mavelikara diocese. Jesus during his public ministry gave special care for the marginalized, the sick and the poor. The differently challenged were always His priority. Following His footsteps we are also called to participate in His ministry. Jyothis is a light for many differently abled children, who need our special support and care. I request all our members and well-wishers to sponsor our students and be a friend of Jyothis.

                        <br />
                        <br />
                        <br />
                        Regards and prayers,
                        <div style={{ padding: 0, width: 150 }}>
                            <Image alt='sign.png' filename='sign.png' />
                        </div>

                        Rt. Rev. Thomas Mar Timotheos

                    </Typography>

                </Paper>

            </div>
        </div >
    )
}
export default OurJyothisFamily
